import { useState, forwardRef } from 'react';
import { pick as _pick, isEmpty as _isEmpty } from 'lodash';
import { Dialog, DialogContent,
  DialogActions, Checkbox, Slide, FormGroup, FormControlLabel,  
  Box,
  Alert} from '@mui/material';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import logo from '../../assets/images/logo-fitradio.png';
import { Logo, BtnNext, BtnBack, ContainerWizard, ActionsContainer,
  ActionsContainerModal, ContainerButtons, ContainerOption } from './questionnaire-wizard.styles';
import BusinessForm from './forms/business.form';
import SelectProviderForm from './forms/select-provider.form';
import InformationForm from './forms/information.form';
import QuestionnaireService from '../../core/services/questionnaire.service';
import validationSchema from './models/validation.new-location.schema';
import questionnaireModel from './models/questionnaire.model';
import formInitialValues from './models/new-location.form.initial.values';
import AppHelper from '../../core/helpers/AppHelper';
import AgreementPage from './agreement';
import SummaryPage from './summary/summary.new-location';
import LoaderWidget from '../../core/widgets/loader/loader.widget';
import { addRegister } from '../../app/features/appSlice';
import { HeaderText } from '../products/form.styles';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const steps = ['', '', ''];
const { formId, formField } = questionnaireModel;

function _renderStepContent(step, messageError, defaultValues, isPackageCode, setProviders) {
  switch (step) {
    case 0:
      return <BusinessForm formField={formField} response={messageError} defaultValues={defaultValues} />;
    case 1:
      return <SelectProviderForm formField={formField} response={messageError} setCurrentProviders={setProviders} />;
    case 2:
      return  <InformationForm 
                formField={formField}
                response={messageError}
                defaultValues={defaultValues}
                isPackageCode={isPackageCode}
              />;
    default:
      return <div>Not Found</div>;
  }
}

const  QuestionnaireNewLocationPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [active, setActive] = useState(false);
  const [defaultValues, setDefaultValues] = useState(formInitialValues);
  const [errorMsg, setErrorMsg] = useState(null);
  const [total, setTotal] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [providers, setProviders] = useState(null);
  const [data, setData] = useState({});
  const [errorMsgCode, setErrorMsgCode] = useState('');
  const [isPackageCode , setIsPackageCode] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [maxValuesError, setMaxValuesError] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const currentValidationSchema = validationSchema[activeStep];
  const questionnaireService = new QuestionnaireService();
  const isLastStep = activeStep === steps.length - 1;

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    values.businessState = values.businessState.label;
    values.businessCity = values.businessCity.label;
    setData(values);
    actions.setSubmitting(false);
  }

  function _handleSubmit(values, actions) {
    setDefaultValues(values);
    setMaxValuesError(false);
    if (isLastStep) {
        const validateMaxValues = values.businessNumberGroup * values.businessClassesPerWeek > maxCount;
        if (maxCount !== 0 && isPackageCode && validateMaxValues) {
          setMaxValuesError(validateMaxValues);
          return;
        }
        _submitForm(values, actions);
      setOpen(true);
    } else {
      switch(activeStep) {
        case 0: validatePackageCode(values);
          break;
        case 1:
        case 2: setActiveStep(activeStep + 1);
          break;
      }
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    if (activeStep === 0) {
      navigate('/account');
    }
    setErrorMsg(null);
    setActiveStep(activeStep - (isPackageCode && isLastStep ? 2 : 1));
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    create(data);
    setOpen(false);
    setActiveStep(activeStep + 1);
  };

  const checkValue = (e) => {
    setActive(e.target.checked);
  };

  const validatePackageCode = async (code) => {
    setOpenLoader(true);
    setErrorMsg(null);
    try {
      const {data: result} = await questionnaireService.validatePackageCode(code);
      setIsPackageCode(result?.exist);

      if (result?.exist) {
        setActiveStep( activeStep + (result?.exist ? 2: 1) );
        setMaxCount(result?.maxCountPerPackageAnnual);
        setErrorMsg(null);
      }
      if (!result?.exist) {
        setErrorMsg('This package code does not exist');
      }
      if (code.packageCode === '') {
        setActiveStep( activeStep +  1 );
        setErrorMsg(null);
      }
      setOpenLoader(false);

    } catch (error) {
      setIsPackageCode(false);
      setActiveStep(activeStep +  1);
      setErrorMsg(AppHelper.getError(error));
      setOpenLoader(false);
    }
  }

  const create = async (data) => {
    let rates = isPackageCode
      ? _pick(data, ['businessTotalMembers', 'businessNumberGroup', 'businessClassesPerWeek', 'packageCode'])
      : _pick(data, ['businessProviders', 'isBgMusic', 'businessTotalMembers', 'businessNumberGroup', 'businessClassesPerWeek', 'businessSquareFootage']);
    setOpenLoader(true);
    setErrorMsg(null);
    try {
      if (rates.isBgMusic === "1" && (_isEmpty(rates.businessClassesPerWeek) || _isEmpty(rates.businessNumberGroup))) {
        rates = {...rates, businessClassesPerWeek: "1", businessNumberGroup: "1", businessTotalMembers: "1"};
        data.businessClassesPerWeek = `1`;
        data.businessNumberGroup = `1`;
        data.businessTotalMembers = `1`;
      }
      const response = await questionnaireService.calculeRates(rates);
      dispatch(addRegister(data));
      setTotal(response.data.total);
      setProviders(response.data.providers);
      setActiveStep(activeStep + 1);
      setErrorMsg(null);
      setOpenLoader(false);
    } catch (error) {
      setErrorMsg(AppHelper.getError(error));
      setOpenLoader(false);
    }
  }

  const applyCodePromo = async (code) => {
    const rates = _pick(data, ['businessProviders', 'businessTotalMembers', 'businessNumberGroup', 'businessClassesPerWeek' ]);
    setErrorMsgCode('');
    setOpenLoader(true);
    setErrorMsg(null);
    const dataTosend = {
      ...rates,
      businessPromotionCode: code
    }
    try {
      const response = await questionnaireService.calculeRates(dataTosend);
      dispatch(addRegister({
        ...data,
        businessPromotionCode: code
      }));
      setIsPackageCode(response.data.isPackage)
      setTotal(response.data.total);
      setProviders(response.data.providers);
      setPromoCode(response.data.promoCode);
      setErrorMsg(null);
      setOpenLoader(false);
    } catch (error) {
      setErrorMsgCode(AppHelper.getError(error));
      setOpenLoader(false);
    }
  }

  return (
    <ContainerWizard>
      <Logo>
          <img alt='FitRadio' src={logo} xs={{alignItems: 'center'}} />
      </Logo>
      <HeaderText variant='body2' textAlign='center'>{`Question 0${activeStep+1}/0${steps.length+1}`}</HeaderText>
      <>
        {activeStep === steps.length ? (
          <SummaryPage
            isPackageCode={isPackageCode}
            total={total}
            providers={providers}
            applyCodePromo={applyCodePromo}
            errorMsgCode={errorMsgCode}
            setErrorMsgCode={setErrorMsgCode}
            promoCode={promoCode}
            formValues={defaultValues}
          />
        ) : (
          <Formik
            initialValues={formInitialValues}
            onSubmit={_handleSubmit}
            validationSchema={currentValidationSchema}
          >
            <Form id={formId}>
              {_renderStepContent(
                activeStep,
                errorMsg,
                defaultValues,
                isPackageCode,
                setProviders,
              )}
              {
               maxValuesError ? (
                <Box>
                  <Alert severity="warning">
                    Member count too high for package selected.
                  </Alert>
                </Box>
               ) : null 
              }
              <ActionsContainer>
                <BtnBack onClick={_handleBack} >
                  Back
                </BtnBack>
                <div>
                  <BtnNext
                    type='submit'
                    variant='contained'
                    color='primary'
                    size='large'
                  >
                    {isLastStep ? 'Submit' : 'Next'}
                  </BtnNext>
                </div>
              </ActionsContainer>
            </Form>
          </Formik>
        )}
      </>
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='modal-agreement'
          maxWidth='lg'
        >
          <DialogContent>
            <AgreementPage />
          </DialogContent>
          <DialogActions sx={{padding: '20px'}}>
            <ActionsContainerModal>
              <ContainerOption sx={{background: active ? 'rgba(245, 166, 35, 0.25)' : '#ffffff'}}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox onChange={checkValue} />} label={`I have read the terms and conditions above and agree`} />
                </FormGroup>
              </ContainerOption>
              <ContainerButtons>
                <BtnBack onClick={handleClose}>I Disagree</BtnBack>
                <BtnNext onClick={handleSave} disabled={!active}>Finish</BtnNext>
              </ContainerButtons>
            </ActionsContainerModal>
          </DialogActions>
        </Dialog>
      </>
      <LoaderWidget loader={openLoader} />
    </ContainerWizard>
  );
};

export default  QuestionnaireNewLocationPage;

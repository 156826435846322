import { createSlice } from "@reduxjs/toolkit";

export const businessSlice = createSlice({
    name: 'business',
    initialState: {
      business: [],
      current: {},
    },
    reducers: {
      getBusiness: (state, action) => {
        state.business = action.payload;
      },
      setCurrent: (state, action) => {
        state.current = action.payload;
      },
      emptyState: (state) =>  {
        state.business = [];
        state.current = {};
      },
    }
})

export const { getBusiness, setCurrent, emptyState } = businessSlice.actions;

export default businessSlice.reducer;

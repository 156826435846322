import React, {useCallback, useEffect, useState} from 'react';
import {Grid, Typography, Button, Box, Tabs, Tab} from '@mui/material';
import {HeaderText, ContainerForm} from './form.styles';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {pick as _pick, size as _size, toString as _toString} from 'lodash';
import AccountService from '../../core/services/account.service';
import AppHelper from '../../core/helpers/AppHelper';
import {useNavigate} from 'react-router-dom';
import LoaderWidget from '../../core/widgets/loader/loader.widget.js';
import styles from './style.css';
import PropTypes from "prop-types";
import InputField from "../../core/widgets/input.widget";
import SummaryPage from "../questionnaire-wizard/summary/summary.page";
import { useSelector } from 'react-redux';

const optionsBusinessProvider = [
  {
    key: "ASCAP",
    value: "1"
  },
  {
    key: "BMI",
    value: "2"
  },
  {
    key: "SESAC",
    value: "3"
  },
  {
    key: "GMR",
    value: "4"
  }
];

const optionsmusicPlaces = [
  {
    key: "FITNESS CLASSES & BACKGROUND MUSIC",
    value: "0"
  },
  {
    key: "BACKGROUND MUSIC",
    value: "1"
  }
];

const accountService = new AccountService();

const SelectProviderForm = (props) => {

  const [checkoutError, setCheckoutError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [availables, setAvailables] = useState([]);
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [isSummaryPage, setIsSummaryPage] = useState(false);
  const [total, setTotal] = useState(null);
  const [providers, setProviders] = useState(null);
  const [errorMsgCode, setErrorMsgCode] = useState('');
  const [isPackageCode, setIsPackageCode] = useState(false);
  const [data, setData] = useState({});
  const { current } = useSelector((state) => state.business);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const validationSchema = Yup.object().shape({
    businessProviders: currentTabIndex === 0 ? Yup.array().min(1, 'Select at least one provider.')
      .required('Select at least one provider.') : Yup.array(),
    isBgMusic: currentTabIndex === 0 ? Yup.string()
      .required('Select a music option.') : Yup.string(),
    packageCode: currentTabIndex === 1 ? Yup.string().nullable()
      .required('Required group Code.') : Yup.string()
  });

  const handleSubmit = async (values) => {
    try {
      setLoader(true);
      accountService.getAccessToken();
      let isPackage = false;
      if (currentTabIndex === 1) {
        const val = _pick(values, ['packageCode']);
        const {data: result} = await accountService.validatePackageCode(val);
        isPackage = result?.exist;
        setIsPackageCode(result?.exist);
        if (!isPackage) {
          setCheckoutError('This package not exist');
        }
      }
      setLoader(false);
      if (currentTabIndex === 0 || isPackage) {
        await create(values, isPackage)
      }
    } catch (e) {
      setLoader(false);
      setCheckoutError(AppHelper.getError(e));
    }
  };

  const addProduct = async () => {
    try {
      setLoader(true);
      accountService.getAccessToken();
      await accountService.subscribe(data);
      return navigate('/account');
    } catch (e) {
      setLoader(false);
      setCheckoutError(AppHelper.getError(e));
    }
  }

  const create = async (data, isPackage = false) => {
    const rates = isPackage
      ? _pick(data, ['packageCode']) : _pick(data, ['businessProviders', 'isBgMusic']);
    setLoader(true);
    try {
      setData(rates);
      const response = await accountService.calculeRates(rates);
      setTotal(response.data.total);
      setProviders(response.data.providers);
      setIsSummaryPage(true);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setCheckoutError(AppHelper.getError(e));
    }
  }

  const listAvailables = useCallback(async () => {
    try {
      setLoader(true);
      accountService.getAccessToken();
      const {data: result} = await accountService.availablesProv(current.id);
      setAvailables(result);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setCheckoutError(AppHelper.getError(e));
    }
  }, [current]);

  useEffect(() => {
    (async function init() {
      await listAvailables();
    })();
  }, [listAvailables]);

  return (
    <>
      {isSummaryPage ? (
        <SummaryPage
          isPackageCode={isPackageCode}
          total={total}
          providers={providers}
          errorMsgCode={errorMsgCode}
          setErrorMsgCode={setErrorMsgCode}
          addProduct={addProduct}
        />
      ) : (
        <>
          <Formik
            initialValues={{
              packageCode: '',
              businessProviders: [],
              isBgMusic: ''
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <HeaderText variant='body2' textAlign='center'>Add Product</HeaderText>
                <Typography variant="h1" textAlign='center'>
                  {`Select the rates to add`}
                </Typography>
                <ContainerForm container spacing={1}>
                  <Box sx={{width: '100%'}}>
                    <Tabs value={currentTabIndex} onChange={handleTabChange}>
                      <Tab label='Provider ' sx={{minWidth: "50%"}}/>
                      <Tab label='Group Code' sx={{minWidth: "50%"}}/>
                    </Tabs>
                  </Box>
                </ContainerForm>

                {/* TAB 1 Contents */}
                {currentTabIndex === 0 && (
                  <>
                    <ContainerForm container spacing={1}>
                      <Box
                        sx={{
                          display: (props.display) ? props.display : 'flex',
                          flexDirection: {
                            xs: 'column',
                            sm: 'row',
                          },
                          width: '100%',
                        }}
                      >
                        <Field name='businessProviders'>
                          {({field}) => {
                            if (_size(availables) >= 0) {
                              return availables.map(option => {
                                const val = _toString(option.id);
                                return (
                                  <React.Fragment key={option.name}>
                                    <article>
                                      <input
                                        type='checkbox'
                                        id={val}
                                        {...field}
                                        value={val}
                                        checked={field.value.includes(val)}
                                      />
                                      <label htmlFor={val}>{option.name}</label>
                                    </article>
                                  </React.Fragment>
                                )
                              });
                            } else {
                              return optionsBusinessProvider.map(option => {
                                return (
                                  <React.Fragment key={option.key}>
                                    <article>
                                      <input
                                        type='checkbox'
                                        id={option.value}
                                        {...field}
                                        value={option.value}
                                        checked={field.value.includes(option.value)}
                                      />
                                      <label htmlFor={option.value}>{option.key}</label>
                                    </article>
                                  </React.Fragment>
                                )
                              });
                            }
                          }}
                        </Field>
                      </Box>
                      {formik.errors.businessProviders && formik.touched.businessProviders && (
                        <div style={{margin: 'auto', fontSize: '13px', color: 'red'}}>
                          {formik.errors.businessProviders}
                        </div>
                      )}
                    </ContainerForm>
                    { (_size(availables) > 0) &&
                      <ContainerForm container spacing={1}>
                        <Box
                          sx={{
                            display: 'block',
                            flexDirection: {
                              xs: 'column',
                              sm: 'row',
                            },
                            width: '100%',
                          }}
                        >
                          <Field name='isBgMusic'>
                            {({field}) => {
                              return optionsmusicPlaces.map((option) => {
                                return (
                                  <React.Fragment key={option.key}>
                                    <article
                                      style={(field.value === option.value) ? {
                                        backgroundColor: '#f5a623',
                                        color: 'white'
                                      } : {}}>
                                      <input
                                        type="radio"
                                        id={option.value}
                                        {...field}
                                        value={option.value}
                                        checked={field.value === option.value}
                                      />
                                      <label htmlFor={option.value}>{option.key}</label>
                                    </article>
                                  </React.Fragment>
                                );
                              });
                            }}
                          </Field>
                        </Box>
                        {formik.errors.isBgMusic && formik.touched.isBgMusic && (
                          <div style={{margin: 'auto', fontSize: '13px', color: 'red'}}>
                            {formik.errors.isBgMusic}
                          </div>
                        )}
                      </ContainerForm>
                    }
                  </>
                )}

                {/* TAB 2 Contents */}
                {currentTabIndex === 1 && (
                  <ContainerForm container spacing={1}>
                    <Box
                      sx={{
                        display: (props.display) ? props.display : 'flex',
                        flexDirection: {
                          xs: 'column',
                          sm: 'row',
                        },
                        width: '100%',
                      }}
                    >
                      <InputField name="packageCode" label="Do you have a Group Code"
                                  sx={{
                                    marginBottom: '5px', "& .MuiFormHelperText-root.Mui-error": {
                                      color: "red !important", fontSize: '13px'
                                    }
                                  }} fullWidth/>
                    </Box>
                  </ContainerForm>
                )}
                <Grid sx={{textAlign: 'center'}}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{color: 'white', fontWeight: 'bold', fontSize: '17px'}}
                  >
                    NEXT
                  </Button>
                </Grid>
                <Grid>

                </Grid>
              </form>
            )}
          </Formik>
        </>
      )}
      <LoaderWidget loader={loader}/>
      <div className={'errorCustom'} sx={styles.erroCustom}>
        {checkoutError}
      </div>
    </>
  );
};

export default SelectProviderForm;

SelectProviderForm.propTypes = {
  display: PropTypes.string,
};


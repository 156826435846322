import { styled } from '@mui/material/styles';
import { Typography, ListItemButton } from '@mui/material';

export const BusinessNameText = styled(Typography)({
  fontFamily: 'Bebas Neue',
  fontSize: '42px',
  fontWeight: 'bold',
  lineHeight: 1,
});

export const Title = styled(Typography)({
  fontFamily: 'Bebas Neue',
  fontSize: '48px',
  fontWeight: 'bold'
});

export const Subtitle = styled(Typography)({
  fontFamily: 'Montserrat',
  fontSize: '14px'
});

export const ElementNavbar = styled(ListItemButton)({
  '& .active': {
    color: 'blue'
  }
});



import AppService from './AppService';
import { serialize } from 'object-to-formdata';

class AccountService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('account');
  }

  getAccount(businessId) {
    return this.http.get(`web${this.path}/info/${businessId}`);
  }

  getBusiness() {
    return this.http.get(`web${this.path}/business`, {
      headers: {
        'Authorization': 'Bearer ' + this.getAccessToken(),
        'Content-Type': 'application/json'
      }
    });
  }

  newDefaultBusiness(businessId) {
    return this.http.post(`web${this.path}/business/default/${businessId}`, {
      headers: {
        'Authorization': 'Bearer ' + this.getAccessToken(),
        'Content-Type': 'application/json'
      }
    });
  }

  getMembership() {
    return this.http.get(`web${this.path}/membership`);
  }

  getAgreements() {
    return this.http.get(`web${this.path}/agreements`);
  }

  getAgreementsByBusiness(businessId) {
    return this.http.get(`web${this.path}/agreements/business/${businessId}`);
  }

  cancelMembership() {
    const data = {};
    const formData = serialize(data);
    return this.http.patch(`web${this.path}/cancel-annual-renewal`, formData);
  }

  subscribe(data) {
    const formData = serialize(data);
    return this.http.post(`web${this.path}/subscribe`, formData);
  }

  subscribeNewLocation(data) {
    const formData = serialize(data);
    return this.http.post(`web${this.path}/new-business`, formData, {
      headers: {
        'Authorization': 'Bearer ' + this.getAccessToken(),
        'Content-Type': 'application/json'
      }
    });
  }

  availablesProv(businessId) {
    return this.http.get(`web${this.path}/availables-providers/${businessId}`);
  }

  validatePackageCode (data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/package-code`, formData);
  }

  calculeRates(data) {
    const formData = serialize(data);
    return this.http.post(`/web${this.path}/calculate-rates`, formData);
  }
}

export default AccountService;

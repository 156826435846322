import { createContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
} from "@mui/material";
import {
  ContainerSummary,
  TitleSummary,
  TopSummary,
  GridSummary,
  GridSummaryText,
  GridSummarySecundary,
  GridSummaryTextSecundary,
  GridSummaryTotal,
  GridSummaryTotalTitle,
  GridSummaryTotalText,
  MountTotal,
  Period,
  GridValidation,
  GridAction,
  ButtonCheckout,
  GridTotal,
  GridSummaryTextTitle,
  GridPromo,
  GridSummaryTextCode,
} from "./summary.styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountService from "../../../core/services/account.service";
import { useDispatch } from "react-redux";
import { getBusiness } from "../../../app/features/business/businessSlice";
import { useNavigate } from "react-router-dom";

const SummaryPage = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [gotCode, setGotCode] = useState(false);
  const [applyCode, setApplyCode] = useState(false);
  const [code, setCode] = useState("");
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  
  const accountService = new AccountService();

  // eslint-disable-next-line
  const {
    isPackageCode,
    total,
    providers,
    applyCodePromo,
    errorMsgCode,
    setErrorMsgCode,
    promoCode,
    addProduct,
    formValues
  } = props;

  const onSubmitNewLocation = async (data) => {
    setIsLoadingBtn(true);
    const response = await accountService.subscribeNewLocation(data);
    try {
      if (response.status === 200) {
        dispatch(getBusiness(response.data));
        navigate('/account');
        // logic to open popup to redirect new location or stay to current
      } else {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch(error) {
      console.log(error);
    } finally {
      setIsLoadingBtn(false);
    }
  }

  return (
    <>
      <ContainerSummary>
        <TopSummary>DONE!</TopSummary>
        <TitleSummary
          variant="h1"
          textAlign="center"
        >{`See your rates below`}</TitleSummary>
        <Typography>{`We will email you all of your licensing information when you are finished checking out.`}</Typography>
        {providers?.map((item, i) => {
          if (isPackageCode && item.id === "fitradio") return null;
          return (
            <div key={i}>
              <GridSummary>
                <GridSummaryTextTitle>{item.id}</GridSummaryTextTitle>
                <GridSummaryText>
                  {!isPackageCode ? `$${item.monthly}` : ""}
                </GridSummaryText>
              </GridSummary>
              <Divider />
            </div>
          );
        })}
        <GridSummarySecundary />
        <GridSummarySecundary />
        <GridSummarySecundary />
        {!isPackageCode && (
          <>
            <GridSummarySecundary>
              <GridSummaryTextSecundary>
                Rights Management Fee
              </GridSummaryTextSecundary>
              <GridSummaryTextSecundary>
                {!isPackageCode ? `$${total?.rightManagementFee || 0}` : ""}
              </GridSummaryTextSecundary>
            </GridSummarySecundary>
            <GridSummarySecundary>
              <GridSummaryTextSecundary>
                Processing Fee
              </GridSummaryTextSecundary>
              <GridSummaryTextSecundary>
                {!isPackageCode ? `$${total?.processingFee || 0}` : ""}
              </GridSummaryTextSecundary>
            </GridSummarySecundary>
            {promoCode && (
              <GridSummarySecundary>
                <GridSummaryTextCode>
                  PromoCode ({promoCode.codeManagementFee || 0})
                </GridSummaryTextCode>
                <GridSummaryTextCode>
                  -${Math.abs(total?.subTotalManagementFee || 0)}
                </GridSummaryTextCode>
              </GridSummarySecundary>
            )}
          </>
        )}
        <GridSummaryTotal>
          <Grid>
            <GridSummaryTotalTitle>Total</GridSummaryTotalTitle>
            <GridSummaryTotalText>
              This is your monthly payment
            </GridSummaryTotalText>
          </Grid>
          <Grid>
            <GridTotal>
              <MountTotal>${total?.totalPayment || 0}</MountTotal>
            </GridTotal>
            <Period>per month</Period>
          </Grid>
        </GridSummaryTotal>
        <GridPromo>
          {!addProduct &&
            (!gotCode ? (
              !isPackageCode && (
                <Button
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={() => setGotCode(true)}
                >
                  Optional Partner Code?
                </Button>
              )
            ) : (
              <TextField
                type="text"
                autoComplete="none"
                value={code}
                onChange={(e) => setCode(e.target.value.toUpperCase())}
                placeholder="Enter promo code here"
                error={errorMsgCode.length > 0}
                helperText={errorMsgCode}
                InputProps={{
                  endAdornment: !applyCode ? (
                    <Button
                      onClick={() => {
                        if (code.length === 0) {
                          setErrorMsgCode("Please enter a promo code");
                          return;
                        }
                        setApplyCode(true);
                        applyCodePromo(code);
                      }}
                    >
                      Apply
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setApplyCode(false);
                        setCode("");
                        setErrorMsgCode("");
                        applyCodePromo("");
                      }}
                    >
                      Remove
                    </Button>
                  ),
                }}
                disabled={applyCode}
              />
            ))}
        </GridPromo>
        <GridValidation>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={(e) => setCheck(e.target.checked)}
                />
              }
              label={`I am aware that this is a yearly agreement with the PROs and I agree to pay all monthly payments for the next year.`}
            />
          </FormGroup>
        </GridValidation>
        <GridAction>
          {addProduct ? (
            <ButtonCheckout
              color="primary"
              fullWidth
              onClick={addProduct}
              disabled={!check}
            >
              Add
            </ButtonCheckout>
          ) : (
            <ButtonCheckout
              color="primary"
              fullWidth
              onClick={() => onSubmitNewLocation(formValues)}
              disabled={!check || isLoadingBtn}
            >
              Subscribe
            </ButtonCheckout>
          )}
        </GridAction>
      </ContainerSummary>
    </>
  );
};

export const PaymentContext = createContext({});
export default SummaryPage;

SummaryPage.propTypes = {
  providers: PropTypes.array,
  total: PropTypes.object,
  applyCodePromo: PropTypes.func,
  errorMsgCode: PropTypes.string,
  setErrorMsgCode: PropTypes.func,
  promoCode: PropTypes.object,
  isPackageCode: PropTypes.bool,
  addProduct: PropTypes.func,
  formValues: PropTypes.any,
};

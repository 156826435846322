import questionnaireModel from "./questionnaire.model";

const {
  formField: {
    businessName,
    businessAddress,
    businessCity,
    businessState,
    businessZipCode,
    businessType,
    businessProviders,
    businessTotalMembers,
    businessNumberGroup,
    businessClassesPerWeek,
    isBgMusic,
    businessSquareFootage,
    packageCode,
  }
} = questionnaireModel;

export default {
  [businessName.name]: '',
  [businessAddress.name]: '',
  [businessCity.name]: null,
  [businessState.name]: null,
  [businessZipCode.name]: '',
  [businessType.name]: '',
  [businessProviders.name]: [],
  [businessTotalMembers.name]: '',
  [businessNumberGroup.name]: '',
  [businessClassesPerWeek.name]: '',
  [isBgMusic.name]: '',
  [businessSquareFootage.name]: '',
  [packageCode.name]: '',
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik'
import { Grid, Typography } from '@mui/material';
import InputField from '../../../core/widgets/input.widget';
import { ContainerForm, SecundaryText } from './form.styles';
import AppHelper from '../../../core/helpers/AppHelper';
import LocalitiesService from '../../../core/services/localities.service';
import FormikAutocomplete  from '../../../core/widgets/autocomplete/autocomplete.widget';
import QuestionnaireService from '../../../core/services/questionnaire.service';
import SelectField from '../../../core/widgets/select.widget';
import InputCodeField from '../../../core/widgets/input.code.widget';

const BusinessForm = (props) => {
  const [errorMsg, setErrorMsg] = useState(props.response ?? '');
  const defaultCityId = props?.defaultValues?.businessState?.value ?? '';

  const [states, setStates] = useState([{
    label: '',
    value: ''
  },]);
  const [cities, setCities] = useState([{
    label: '',
    value: ''
  },]);
  const [businessTypesState, setBusinessTypesState] = useState([{
    label: '',
    value: ''
  },]);
  const [cityId, setCityId] = useState(defaultCityId);

  const [isBusinessStateChanged, setIsBusinessStateChanged] = useState(false);

  const localitiesService = new LocalitiesService();
  const questionnaireService = new QuestionnaireService();

  const {
    formField: {packageCode, businessName, businessAddress, businessCity, businessState, businessZipCode, businessType}
  } = props;

  const getStates = async () => {
    try {
      const response = await localitiesService.listStates();
      setStates(response.data);
    } catch (error) {
      AppHelper.getError(error);
    }
  }

  const getCities = async (stateId) => {
    try {
      const response = await localitiesService.listCities(stateId);
      setCities(response.data);
    } catch (error) {
      AppHelper.getError(error);
    }
  }

  const getBusinessTypes = async () => {
    try {
      const response = await questionnaireService.listBusinessTypes();
      setBusinessTypesState(response.data);
    } catch (error) {
      AppHelper.getError(error);
    }
  }

  const getVal = (val) => {
    setIsBusinessStateChanged(true);
    if(!val) return setCities([]);
    setCityId(val.value);
  }

  useEffect(() => {
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (cityId) {
      getCities(cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId])

  useEffect(() => {
    getBusinessTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onCleanErrorMsg = () => {
    setErrorMsg("");
  }

  useEffect(() => {
    setErrorMsg(props.response);
  }
  , [props.response])

  return (
    <>
      <Typography variant="h1" textAlign='center'>
        Now please tell us about your business
			</Typography>
      <ContainerForm container spacing={1}>
        <SecundaryText>
          {"If you have more than one location, each location will need it's own account. You can sign up for another location when you are finished"}
        </SecundaryText>
        <Grid item xs={12} md={12}>
          <InputCodeField name={packageCode.name} label={packageCode.label} fullWidth errorMsg={errorMsg} onCleanErrorMsg={onCleanErrorMsg} />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputField name={businessName.name} label={businessName.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <InputField name={businessAddress.name} label={businessAddress.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            name={businessState.name}
            label={businessState.label}
            component={FormikAutocomplete}
            getValueChild={getVal}
            options={states}
            textFieldProps={{
              fullWidth: true,
              margin: 'normal',
              variant: 'outlined'
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            name={businessCity.name}
            label={businessCity.label}
            component={FormikAutocomplete}
            isBusinessStateChanged={isBusinessStateChanged}
            child
            options={cities}
            textFieldProps={{
              fullWidth: true,
              margin: 'normal',
              variant: 'outlined'
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputField name={businessZipCode.name} label={businessZipCode.label} fullWidth />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectField
            name={businessType.name}
            label={businessType.label}
            data={businessTypesState}
            fullWidth
          />
        </Grid>
      </ContainerForm>
    </>
  )
};

export default BusinessForm;

BusinessForm.propTypes = {
  formField: PropTypes.object,
  form: PropTypes.node,
  defaultValues: PropTypes.object,
  response: PropTypes.string
};

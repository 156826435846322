import { createContext, useState } from "react";
import PropTypes from 'prop-types';
import { Divider, Grid, Typography, FormGroup, FormControlLabel, Checkbox, DialogContent,
  DialogTitle, IconButton, Button, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { DialogComponent } from "../../../core/layouts/widgets/header/header.styles";
import PaymentyPage from "../payment/payment.page";
import { ContainerSummary, TitleSummary, TopSummary, GridSummary,
  GridSummaryText, GridSummarySecundary, GridSummaryTextSecundary,
  GridSummaryTotal, GridSummaryTotalTitle, GridSummaryTotalText,
  MountTotal, Period, GridValidation, GridAction, ButtonCheckout,
  GridTotal, GridSummaryTextTitle, GridPromo, GridSummaryTextCode,
} from './summary.styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SummaryPage = (props) => {
  const [openPayment, setOpenPayment] = useState(false);
  const [check, setCheck] = useState(false);
  const [gotCode, setGotCode] = useState(false);
  const [applyCode, setApplyCode] = useState(false);
  const [code, setCode] = useState('');
  const [isProcessing, setProcessingTo] = useState(false);
  // eslint-disable-next-line
  const { isPackageCode, total, providers, applyCodePromo, errorMsgCode, setErrorMsgCode, promoCode, addProduct } = props;

  return(
    <>
      <ContainerSummary>
        <TopSummary>DONE!</TopSummary>
        <TitleSummary variant='h1' textAlign='center'>{`See your rates below`}</TitleSummary>
        <Typography>{`We will email you all of your licensing information when you are finished checking out.`}</Typography>
        {
          providers?.map((item, i) => {
            if (isPackageCode && item.id === 'fitradio') return null;
            return (
              <div  key={i}>
                <GridSummary>
                  <GridSummaryTextTitle>{item.id}</GridSummaryTextTitle>
                  <GridSummaryText>{!isPackageCode ? `$${item.monthly}` : ''}</GridSummaryText>
                </GridSummary>
                <Divider />
              </div>
            );
          })
        }
        <GridSummarySecundary />
        <GridSummarySecundary />
        <GridSummarySecundary />
        {
          !isPackageCode && (
            <>
          <GridSummarySecundary>
            <GridSummaryTextSecundary>Rights Management Fee</GridSummaryTextSecundary>
            <GridSummaryTextSecundary>{!isPackageCode ? `$${total?.rightManagementFee || 0}` : ''}</GridSummaryTextSecundary>
          </GridSummarySecundary>
          <GridSummarySecundary>
            <GridSummaryTextSecundary>Processing Fee</GridSummaryTextSecundary>
            <GridSummaryTextSecundary>{!isPackageCode ? `$${total?.processingFee || 0}` : ''}</GridSummaryTextSecundary>
          </GridSummarySecundary>
          {
            promoCode && (
              <GridSummarySecundary>
                <GridSummaryTextCode>PromoCode ({promoCode.codeManagementFee || 0})</GridSummaryTextCode>
                <GridSummaryTextCode>-${Math.abs(total?.subTotalManagementFee || 0)}</GridSummaryTextCode>
              </GridSummarySecundary>
            )
          }
            </>
          )
        }
        <GridSummaryTotal>
          <Grid>
            <GridSummaryTotalTitle>Total</GridSummaryTotalTitle>
            <GridSummaryTotalText>This is your monthly payment</GridSummaryTotalText>
          </Grid>
          <Grid>
            <GridTotal>
              <MountTotal>${total?.totalPayment || 0}</MountTotal>
            </GridTotal>
            <Period>per month</Period>
          </Grid>
        </GridSummaryTotal>
        <GridPromo>
          {!addProduct && (
          (!gotCode) ? (
            !isPackageCode && (
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                onClick={() => setGotCode(true)}
              >
                Optional Partner Code?
              </Button>
            )) : (
              <TextField
                type='text'
                autoComplete='none'
                value={code}
                onChange={e => setCode(e.target.value.toUpperCase())}
                placeholder='Enter promo code here'
                error={errorMsgCode.length > 0}
                helperText={errorMsgCode}
                InputProps={{
                  endAdornment: (
                    !applyCode ? (
                      <Button onClick={() => {
                        if (code.length === 0) {
                          setErrorMsgCode('Please enter a promo code');
                          return;
                        }
                        setApplyCode(true);
                        applyCodePromo(code);
                      }}>
                        Apply
                      </Button>
                    ) : (
                      <Button onClick={() => {
                        setApplyCode(false);
                        setCode('');
                        setErrorMsgCode('');
                        applyCodePromo('');
                      }}>
                        Remove
                      </Button>
                    )
                  )
                }}
                disabled={applyCode}

              />
            ))
          }
        </GridPromo>
        <GridValidation>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={check} onChange={e => setCheck(e.target.checked)} />}
              label={`I am aware that this is a yearly agreement with the PROs and I agree to pay all monthly payments for the next year.`}
            />
          </FormGroup>
        </GridValidation>
        <GridAction>
          {addProduct ? (
            <ButtonCheckout color="primary" fullWidth onClick={addProduct} disabled={!check}>Add</ButtonCheckout>
          ) : (
            <ButtonCheckout color="primary" fullWidth onClick={() => setOpenPayment(true)}
                            disabled={!check}>Checkout</ButtonCheckout>
          )}
        </GridAction>
      </ContainerSummary>
      <PaymentContext.Provider value={{ isProcessing, setProcessingTo }}>
        <DialogComponent open={openPayment}>
          <DialogTitle>
            <IconButton
              aria-label="close"
              disabled={isProcessing}
              onClick={() => setOpenPayment(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{overflowX: 'hidden', padding: '40px', height: '440px'}}>
            <PaymentyPage />
          </DialogContent>
        </DialogComponent>
      </PaymentContext.Provider>
    </>
  )
}

export const PaymentContext = createContext({});
export default SummaryPage;

SummaryPage.propTypes = {
  providers: PropTypes.array,
  total: PropTypes.object,
  applyCodePromo: PropTypes.func,
  errorMsgCode: PropTypes.string,
  setErrorMsgCode: PropTypes.func,
  promoCode: PropTypes.object,
};

import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Header, LinkLogo, BoxNav, Btn, NavItem
} from './header.styles';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../../assets/images/logo.svg';
import LoginDialog from '../../../../pages/home/login.dialog';
import { logoutSuccess } from '../../../../app/features/user/userSlice';
import { loginStatus } from '../..//../../app/features/appSlice';
import { theme } from '../../../../theme'
import MultilocationsMenu from './multilocations/menu.widget';
import { emptyState } from '../../../../app/features/business/businessSlice';

const HeaderWidget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { app } = useSelector(state => state.app);

  const handleOpenDialog = () => {
    dispatch(loginStatus());
  };

  const logout = () => {
    dispatch(logoutSuccess());
    dispatch(emptyState());
    navigate('/');
  };

  return (
      <Box position='static' style={{background: theme.palette.body.main}}>
        <Container>
          <Header component='header' >
            <LinkLogo href='/'  >
              <Box component='img' src={logo} />
            </LinkLogo>
            <BoxNav component='ul' >
              <Box component='li'>
                { !user ? <Btn ariant='contained' onClick={()=>navigate('/register')}>
                    Get Started
                </Btn> :
                <MultilocationsMenu />
                }
              </Box>
              <NavItem component='li' >
                { user ? <Btn variant='contained' onClick={logout}>
                  Logout
                </Btn> :
                <Btn variant='contained' onClick={handleOpenDialog}>
                Login
              </Btn>}
              <LoginDialog openDialog={app.openLogin} />
              </NavItem>
            </BoxNav>
          </Header>
        </Container>
      </Box>
  );
};

export default HeaderWidget;

import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';

import {
  DialogComponent,
  TitleSection,
  BoxForm,
  BoxPswLabels,
  BtnLogin,
  BoxForgotPsw,
  AccountMsg,
  BtnLink,
  BtnClose,
  BoxError
} from '../../core/layouts/widgets/header/header.styles';

import SendEmailDialog from './send-email.dialog';

import { ErrorMessage, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../core/services/auth.service.js';
import AppHelper from '../../core/helpers/AppHelper';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../app/features/user/userSlice';
import { loginStatus } from '../../app/features/appSlice';
import { getBusiness as businessList, setCurrent } from '../../app/features/business/businessSlice';

import * as Yup from 'yup';
import AccountService from '../../core/services/account.service.js';

const LoginDialog = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authService = new AuthService();
  const [textError, setTextError] = useState(null);
  const [seePW, setSeePw] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);

  const accountService = new AccountService();

  const handleDialogClose = () => {
    dispatch(loginStatus());
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const getBusiness = async () => {
    try {
      // setLoader(true);
      const business = await accountService.getBusiness();
      dispatch(businessList(business.data));
      dispatch(setCurrent(business.data.find((item) => (item.isDefault))));
      // setLoader(false);
    } catch (e) {
      // setLoader(false);
    }
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid e-mail')
      .required('Required'),
    password: Yup.string().min(8, 'Minimum 8 characters').required('Required'),
  });

  const onSubmit = async (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };

    try {
      const response = await authService.login(data);
      const accessToken = response.data.token;
      authService.setAccessToken(accessToken);
      const r2 = await authService.me();

      let payload = { ...r2.data, token: accessToken };

      dispatch(loginSuccess(payload));
      if(r2.status === 200 || r2.status === 201) {
        getBusiness();
      }
      navigate('/account', { replace: true });
      dispatch(loginStatus());
      
    } catch (error) {
      setTextError(AppHelper.getError(error));
    }
  };

  const showPassword = () => {
    setSeePw(!seePW);
  }

  const sendEmail = () => {
    dispatch(loginStatus());
    setOpenSendEmail(true);
  }
  const sendRegister = () => {
    dispatch(loginStatus());
    navigate('/register', { replace: true });
  }

  return (
    <div>
      <DialogComponent open={props.openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          <TitleSection>
            <Typography variant='h3'>Welcome Back!</Typography>
            <Typography variant='h6'>Login to continue</Typography>
          </TitleSection>
        </DialogTitle>
        <DialogContent>
          <BoxForm>
          <Formik
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={onSubmit}
            >
              {(props) => {
                const { values, handleChange } = props;
                return (
                  <Form component='form'>
                    <Box>
                      <Box sx={{ margin: '0 0 10px 0' }}>
                        <Typography component='label' htmlFor='email'>
                          Email Address
                        </Typography>
                      </Box>
                      <TextField
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        name='email'
                        variant='outlined'
                        label='Enter email address...'
                        helperText={<ErrorMessage name='email' />}
                      />
                    </Box>
                    <Box>
                      <BoxPswLabels>
                        <Typography component='label'>Password</Typography>
                        <Button component='label' sx={{textTransform: 'none'}} onClick={showPassword}>Show Password</Button>
                      </BoxPswLabels>
                      <TextField
                        fullWidth
                        name='password'
                        type={seePW ? 'text' : 'password'}
                        variant='outlined'
                        label='Type your password...'
                        value={values.password}
                        onChange={handleChange}
                        helperText={<ErrorMessage name='password' />}
                      />
                      <BoxForgotPsw>
                        <Typography>Forgot Password?&nbsp;</Typography>
                        <Typography>
                          <BtnLink onClick={sendEmail}>Send Email</BtnLink>
                        </Typography>
                      </BoxForgotPsw>
                    </Box>
                    <Box>
                      <BtnLogin
                        type='submit'
                        variant='contained'
                        disabled={props.isSubmitting}
                        fullWidth
                      >
                        {props.isSubmitting ? 'Loading' : 'Sign In'}
                      </BtnLogin>
                    </Box>
                    <BoxError>
                      {textError}
                    </BoxError>
                  </Form>
                );
              }}
            </Formik>
          </BoxForm>
          <AccountMsg>
            <Typography>Do not have an account?&nbsp;</Typography>
            <Typography>
              <BtnLink onClick={sendRegister}>Sign up</BtnLink>
            </Typography>
          </AccountMsg>
        </DialogContent>
        <DialogActions>
          <Box>
            <BtnClose variant='outlined' onClick={handleDialogClose}>
              Close
            </BtnClose>
          </Box>
        </DialogActions>
      </DialogComponent>
      <SendEmailDialog openDialog={openSendEmail} setOpenDialog={setOpenSendEmail} />
    </div>
  );
};

export default LoginDialog;

LoginDialog.propTypes = {
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  isSubmitting: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
};
